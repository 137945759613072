import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import { Link } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";

require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class CasualtyTypesEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},
            id: '',
            name: '',
            detail: '',
            rows: [],
            sendPageNumber: '',
            sendPerPage: '',
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,
            totalData: 0,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.title = 'Abadi - Edit Casualty Type';

        const id = this.props.match.params.id;

        Api.get('/casualty-type/'+id).then(resp => {
            if (resp.data) {
                let data = resp.data;
                this.setState({
                    id: data.id,
                    name: data.name,
                    detail: data.detail,
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    handleChange (e, prop){
        this.setState({ [prop]: e.target.value });
    };

    showMessage = (status, message) => {
        if (status) toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
        else toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const id = this.props.match.params.id;
        if (!validator.allValid()) {
            this.setState({ errors: validator.getErrorMessages() });
            return false;
        }

        this.setState({ errors: {}, loadingButton: true });

        let params = {
            name : this.state.name,
            detail : this.state.detail,
        };

        Api.patch('/casualty-type/' + id, params, false).then(resp => {
            this.setState({ loadingButton: true });
            history.push('/casualty-types');
            this.showMessage(true, 'Project Type updated');
        }).catch(err => {
            if (ErrorHandling.checkErrorTokenExpired(err)){

            } else {
                this.setState({ errors: err.errors, loadingButton: false });
                this.showMessage(false, 'Invalid format data');
            }
        });

    };

    handleGo  = (link) => {
        history.push(link);
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h1 className="page-title">Edit Casualty Type</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/casualty-types" >Casualty Type</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Edit</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12 mt-3 px-lg-5">
                    <div className="table-wrapper">
                        <form name="edit" id="editType" className="row" noValidate>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Type</label>
                                    <TextField
                                        variant="outlined"
                                        type='text'
                                        id='name'
                                        name="name"
                                        label="Type"
                                        onChange={(e) => this.handleChange(e, 'name')}
                                        value={this.state.name}
                                        fullWidth
                                    />
                                    {validator.message('name', this.state.name, 'required')}
                                    <div className='text-danger'>{this.state.errors.name}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Description</label>
                                    <TextField
                                        variant="outlined"
                                        type='text'
                                        id='detail'
                                        name="detail"
                                        label="Description"
                                        onChange={(e) => this.handleChange(e, 'detail')}
                                        value={this.state.detail}
                                        fullWidth
                                    />
                                    {validator.message('detail', this.state.detail, '')}
                                    <div className='text-danger'>{this.state.errors.detail}</div>
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <Button
                                    variant="contained"
                                    className="mr-3"
                                    onClick={() => this.handleGo('/casualty-types')}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className=""
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton && 'disabled' }
                                >
                                    Submit{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <ToastContainer autoClose={3000} />
            </div>
        )
    }
}

export default CasualtyTypesEdit;
