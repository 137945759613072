import React,{Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from "react-toastify";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {history} from "../../../shared/configure-store";

require('dotenv').config();

class CasualtyTypes extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            orderBy: '',
            sortedBy: '',
            searchBy: '',

            roles: [],
            showDialog: false,
            idDelete: '',
            currDelete: '',

            name: '',
            detail: '',

            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,

            isEdit: false,
            isDelete: false,
            isCreate: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.title = 'Abadi - Client';
        this.__fetchData(false);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __fetchData = update => {
        this._isMounted = true;

        let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;
        let sort = this.state.orderBy ? '&orderBy=' + this.state.orderBy + '&sortedBy=' + this.state.sortedBy : '';
        let search = this.state.searchBy ? '&search=' + this.state.searchBy : '';
        let route = '';

        if (search) {
            route = '/casualty-type?limit='+this.state.perPage+sort+search;
        } else {
            route = '/casualty-type?limit='+this.state.perPage+'&page='+page+sort+search;
        }

        Api.get(route).then(resp => {
            if (this._isMounted) {
                if (resp?.data) {
                    console.log('resp.data SUCCESS', resp?.data)
                    this.setState({
                        rows: resp?.data,
                        perPage: resp?.meta?.pagination?.per_page,
                        currentPage: resp?.meta?.pagination?.current_page,
                        currentPageTable: resp?.meta?.pagination?.current_page - 1,
                        total: resp?.meta?.pagination?.total,
                        loading: false,
                        isEdit: resp?.meta?.custom?.isEdit,
                        isDelete: resp?.meta?.custom?.isDelete,
                        isCreate: resp?.meta?.custom?.isCreate,
                    });
                } else {
                    this.setState({ loading: false });
                    console.log('resp.data FAILED', resp?.data)
                }
            }
        });
    };

    handleSearch = () => {
        this.__fetchData(false);
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') this.__fetchData(false);
    };

    handleSort = (val) => {
        let sortedBy = this.state.sortedBy;

        if (val !== this.state.orderBy){
            sortedBy = 'asc';
        } else {
            if (sortedBy && sortedBy === 'asc' ){
                sortedBy = 'desc';
            } else {
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChange (e, prop){
        this.setState({ [prop]: e.target.value });
    };

    handleOpen = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.name,
        });
    };

    handleClose = () => {
        this.setState({ showDialog :false });
    };

    handleDelete = () => {
        if (this.state.idDelete) {
            Api.delete('/casualty-type/'+this.state.idDelete, '', false).then(resp => {
                this.setState({ showDialog :false });
                this.showMessage(true, 'Casualty Type successfully deleted');
                this.__fetchData(false);
            }).catch(err => {
                this.setState({ showDialog :false });
                this.showMessage(true, 'Casualty Type successfully deleted');
                this.__fetchData(false);
            });
        }
    };

    showMessage = (status, message) => {
        if (status) toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
        else toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value, 10),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };

    handleGo  = (link) => {
        history.push(link);
    };

    handleExport = () => {
        this._isMounted = true;
        this.setState({ loadingButton: true });

        let route = '/casualty-type?export=true';

        Api.get(route).then(resp => {
            if (this._isMounted) {
                if (resp.data) {
                    let url = resp.data.url;
                    window.open(url);
                    this.setState({
                        url: resp.data && resp.data.url,
                        loadingButton: false,
                    })
                }
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loadingButton: false });
        });

    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h1 className="page-title">Casualty Types</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Casualty Types</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12 mt-3 px-lg-5">
                    <div className="table-wrapper">
                        <div className="row align-items-center mb-md-3">
                            <div className="col-md-6">
                                <TextField
                                    id="input-with-icon-textfield"
                                    variant="outlined"
                                    className="search-field"
                                    onChange={(e) => this.handleChange(e, 'searchBy')}
                                    onKeyDown={this.handleKeyDown}
                                    onBlur={this.handleSearch}
                                    placeholder="Search here"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    aria-label="Search click"
                                                    onClick={this.handleSearch}
                                                >
                                                    <i className="fas fa-search"> </i>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="col-md-6 text-md-right">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className="mb-3 mr-2 mb-md-0"
                                    onClick={() => this.handleGo('/casualty-types/add')}
                                >
                                    Create Casualty Type
                                </Button>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    className='mb-3 mb-md-0'
                                    onClick={() => this.handleExport()}
                                >
                                    Export { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                            </div>
                        </div>

                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className="clickable" onClick={() => this.handleSort('name')} >Name
                                        {this.state.orderBy === 'name' && (
                                            <span className="icon-sort">{
                                                this.state.sortedBy === 'asc' ? (
                                                    <i className="fas fa-sort-up"> </i>
                                                ) : (
                                                    <i className="fas fa-sort-down"> </i>
                                                )
                                            }</span>
                                        )}
                                    </TableCell>
                                    <TableCell className="clickable" onClick={() => this.handleSort('detail')} >Detail
                                        {this.state.orderBy === 'detail' && (
                                            <span className="icon-sort">{
                                                this.state.sortedBy === 'asc' ? (
                                                    <i className="fas fa-sort-up"> </i>
                                                ) : (
                                                    <i className="fas fa-sort-down"> </i>
                                                )
                                            }</span>
                                        )}
                                    </TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.loading ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center" className="py-5">
                                            <CircularProgress color="primary"  />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.rows.length === 0 ? (
                                        <TableRow style={{ height: 33 * this.state.perPage}}>
                                            <TableCell colSpan={5} style={{ textAlign: "center" }}>No Casualty Type data</TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.map(row => (
                                            <TableRow
                                                key={row.id}
                                                selected={row.selected}>
                                                <TableCell>
                                                    <span className='text-uppercase'>{row.name === null ? 'NA' : row.name}</span>
                                                </TableCell>
                                                <TableCell>{row.detail === null ? 'NA' : row.detail}</TableCell>
                                                <TableCell>
                                                    <div className="d-flex">
                                                        <Link to={"/casualty-types/edit/" + row.id} className="btn-icon mr-2">
                                                            <i className="fas fa-edit"> </i>
                                                        </Link>
                                                        <button className="btn-icon" onClick={() => this.handleOpen(row)}>
                                                            <i className="fas fa-trash-alt"> </i>
                                                        </button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )
                                )}

                            </TableBody>
                        </Table>
                    </div>
                    {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={this.state.total}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPageTable}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    /> */}
                </div>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">DELETE DATA: {this.state.currDelete}</DialogTitle>
                    <DialogContent>
                        <p>Are you sure you wanna delete this casualty type?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            className="mr-3"
                            onClick={this.handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className=""
                            onClick={this.handleDelete}
                            disabled={this.state.loadingButton && 'disabled' }
                        >
                            Delete{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                        </Button>
                    </DialogActions>
                </Dialog>

                <ToastContainer autoClose={3000} />

            </div>
        )
    }
}

export default CasualtyTypes;
