import React,{Component} from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Api from '../../../libraries/api';
import TuneIcon from '@material-ui/icons/Tune';
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from 'react-toastify';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../presentational/table-pagination-actions';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import {history} from '../../../shared/configure-store';
import queryString from 'query-string';

require('dotenv').config();


const styleWidth = {
    minWidth: 'none',
};

const styleWidth1 = {
    minWidth: 'none',
};

const styleMax = {
    width: 'auto',
};

class Projects extends Component {
    _isMounted = false;

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            orderBy: '',
            orderByStatus: '',
            sortedBy: '',
            searchBy: '',

            roles: [],
            showDialog: false,
            showDialogFilter: false,
            idDelete: '',
            currDelete: '',

            name: '',
            detail: '',

            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,

            isEdit: false,
            isDelete: false,
            isCreate: false,

            case_number: '',
            case_type: '',
            project_number: '',
            ext_project_number: '',
            vessel_name: '',
            casualty: '',
            casualty_type: '',
            client_id: '',
            typeId: '',
            detail_type_id: '',
            projectStatusReportId: '',
            projectStatusApprovalId: '',
            case_status_id: '',
            projectStatus: '',
            surveyorId: '',
            adminId: '',
            finalReviewerId: '',
            surveyId: '',
            client_type: '',
            remarks: '',
            url: '',
            vessel_type: '',
            vessel_type_id: '',
            estimate_cost: '',
            place_of_repair: '',
            vessel_grt: '',
            vessel_yob: '',
            place_survey: '',
            start_instruction_date: null,
            end_instruction_date: null,

            types: [],
            detailTypes: [],
            clients: [],
            project_status: [],
            surveyors: [],
            admins: [],
            finalReviewers: [],
            report_status: [],
            case_status: [],
            approval_status: [],
            survey_status: [],
            vesselTypes: [],
            casualtyTypes: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitFilter = this.handleSubmitFilter.bind(this);
        this.handleResetFilter = this.handleResetFilter.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi - Projects';
        let url = this.props.location.search;
        let urlParams = queryString.parse(url);

        this.setState({
            queryParam : urlParams ? true : false,
            case_number : urlParams.case_number ? urlParams.case_number : '',
            project_number : urlParams.project_number ? urlParams.project_number : '',
            ext_project_number : urlParams.ext_project_number ? urlParams.ext_project_number : '',
            vessel_name : urlParams.vessel_name ? urlParams.vessel_name : '',
            vessel_type_id : urlParams.vessel_type_id ? urlParams.vessel_type_id : '',
            remarks : urlParams.vessel_name ? urlParams.remarks : '',
            client_type : urlParams.vessel_name ? urlParams.client_type : '',
            casualty: urlParams.casualty ? urlParams.casualty : '',
            casualty_type_id: urlParams.casualty_type_id ? urlParams.casualty_type_id : '',
            client_id: urlParams.client_id ? urlParams.client_id : '',
            typeId: urlParams.typeId ? urlParams.typeId : '',
            projectStatusReportId: urlParams.projectStatusReportId ? urlParams.projectStatusReportId : '',
            projectStatusApprovalId: urlParams.projectStatusApprovalId ? urlParams.projectStatusApprovalId : '',
            case_status_id: urlParams.case_status_id ? urlParams.case_status_id : '',
            projectStatus: urlParams.projectStatus ? urlParams.projectStatus : '',
            surveyorId: urlParams.surveyorId ? urlParams.surveyorId : '',
        },() => {
            this.__fetchData(false);
        });

        Api.get('/clients?limit=0').then(resp => {

            if (resp.data) {

                let data = resp.data.map(o=>{
                    return {
                        label: o.name,
                        value: o.id,
                    }
                });

                this.setState({
                    clients: data,
                })

            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/project/status?limit=0').then(resp => {
            if (resp.data) {
                let report = [];
                let approval = [];

                if(resp.data.length > 0) {
                    _.forEach(resp.data, function(v,i){
                        if(v.type === 'Report') {
                            report.push(v);
                        }else if(v.type === 'Approval') {
                            approval.push(v);
                        }
                    });
                }

                this.setState({
                    report_status: report,
                    approval_status: approval,
                });
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/cases-status?limit=0').then(resp => {
            if (resp.data) {
                let case_status = [];

                if (resp.data.length > 0) {
                    _.forEach(resp.data, function(v,i){
                        case_status.push(v);
                    });
                }

                this.setState({ case_status });
            }
        }).catch(err => {
            console.log(err);
        });

        Api.get('/survey/status?limit=0').then(resp => {
            if (resp.data) {

                this.setState({
                    survey_status: resp.data,
                })
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/types?limit=0').then(resp => {
            if (resp.data) {
                this.setState({
                    types: resp.data,
                });
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/detailtypes?limit=0').then(resp => {
            if (resp.data) {
                this.setState({ detailTypes: resp.data });
            }
        }).catch(err => {
            console.log(err);
        });

        Api.get('/users?roles=surveyor&limit=0').then(resp => {

            if (resp.data) {

                this.setState({
                    surveyors: resp.data,
                });
            }

        }).catch(err => {
            console.log(err);
        });
        let admins = [];

        Api.get('/users?additional=adm-report&limit=0').then(resp => {

            if (resp.data) {
                _.forEach(resp.data, function(v,i){
                    admins.push(v)
                });

                Api.get('/users?additional=adm-approval&limit=0').then(resp => {

                    if (resp.data) {
                        _.forEach(resp.data, function(v,i){
                            admins.push(v)
                        });
                        this.setState({
                            admins: admins,
                        });
                    }

                }).catch(err => {
                    console.log(err);
                });
            }

        }).catch(err => {
            console.log(err);
        });

        let finalReviewers = [];

        Api.get('/users?additional=final-reviewer&limit=0').then(resp => {

            if (resp.data) {
                _.forEach(resp.data, function(v,i){
                    finalReviewers.push(v)
                });

                Api.get('/users?additional=adm-approval&limit=0').then(resp => {

                    if (resp.data) {
                        _.forEach(resp.data, function(v,i){
                            finalReviewers.push(v)
                        });
                        this.setState({ finalReviewers });
                    }
                }).catch(err => {
                    console.log(err);
                });
            }
        }).catch(err => {
            console.log(err);
        });

        Api.get('/vessel-type').then(resp => {
            if (resp.data) {
                let data = resp.data.map((o) => ({ label: o.name, value: o.id }));
                this.setState({ vesselTypes: data });
            }
        });

        Api.get('/casualty-type').then(resp => {
            if (resp.data) {
                let data = resp.data.map((o) => ({ label: o.name, value: o.id }));
                this.setState({ casualtyTypes: data });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps() {
        setTimeout(function () {
            let url = this.props.location.search;
            let urlParams = queryString.parse(url);

            this.setState({
                queryParam : urlParams ? true : false,
                case_number : urlParams.case_number ? urlParams.case_number : '',
                project_number : urlParams.project_number ? urlParams.project_number : '',
                ext_project_number : urlParams.ext_project_number ? urlParams.ext_project_number : '',
                vessel_name : urlParams.vessel_name ? urlParams.vessel_name : '',
                vessel_type_id : urlParams.vessel_type_id ? urlParams.vessel_type_id : '',
                remarks : urlParams.vessel_name ? urlParams.remarks : '',
                client_type : urlParams.vessel_name ? urlParams.client_type : '',
                casualty: urlParams.casualty ? urlParams.casualty : '',
                casualty_type_id: urlParams.casualty_type_id ? urlParams.casualty_type_id : '',
                client_id: urlParams.client_id ? urlParams.client_id : '',
                typeId: urlParams.typeId ? urlParams.typeId : '',
                projectStatusReportId: urlParams.projectStatusReportId ? urlParams.projectStatusReportId : '',
                projectStatusApprovalId: urlParams.projectStatusApprovalId ? urlParams.projectStatusApprovalId : '',
                case_status_id: urlParams.case_status_id ? urlParams.case_status_id : '',
                projectStatus: urlParams.projectStatus ? urlParams.projectStatus : '',
                surveyorId: urlParams.surveyorId ? urlParams.surveyorId : '',
                adminId: urlParams.adminId ? urlParams.adminId : '',
                finalReviewerId: urlParams.finalReviewerId ? urlParams.finalReviewerId : '',
                surveyId: urlParams.surveyId ? urlParams.surveyId : '',
            },() => {
                this.__fetchData(false);
            });

        }.bind(this), 1);
    }

    __fetchData = update => {
        this._isMounted = true;

        let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;

        let params = {
            case_number : this.state.case_number,
            case_type : this.state.case_type,
            project_number : this.state.project_number,
            ext_project_number : this.state.ext_project_number,
            vessel_name : this.state.vessel_name,
            vessel_type_id : this.state.vessel_type ? this.state.vessel_type.value : '',
            client_type : this.state.client_type,
            casualty: this.state.casualty,
            casualty_type_id: this.state.casualty_type ? this.state.casualty_type.value : '',
            client_id: this.state.client_id ? this.state.client_id.value : '',
            typeId: this.state.typeId ? this.state.typeId : '',
            detail_type_id: this.state.detail_type_id ? this.state.detail_type_id : '',
            remarks: this.state.remarks ? this.state.remarks : '',
            projectStatusReportId: this.state.projectStatusReportId,
            projectStatusApprovalId: this.state.projectStatusApprovalId,
            case_status_id: this.state.case_status_id ?? '',
            projectStatus: this.state.projectStatus,
            survey_status_id: this.state.surveyId ?? '',
            surveyorId: this.state.surveyorId,
            adminId: this.state.adminId,
            finalReviewerId: this.state.finalReviewerId,
            estimation_cost: this.state.estimate_cost,
            place_of_repair: this.state.place_of_repair,
            vessel_grt: this.state.vessel_grt,
            vessel_yob: this.state.vessel_yob,
            survey_comment: this.state.place_survey,
            start_instruction_date: this.state.start_instruction_date ? moment(this.state.start_instruction_date).startOf('year').format('YYYY-MM-DD') : '',
            end_instruction_date: this.state.end_instruction_date ? moment(this.state.end_instruction_date).endOf('year').format('YYYY-MM-DD') : '',
        };

        let sort = this.state.orderBy ? '&orderBy=' + this.state.orderBy + '&sortedBy=' + this.state.sortedBy : '';
        let search = this.state.searchBy ? '&search=' + this.state.searchBy : '';
        let filter = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let route = '';

        if(this.state.showDialogFilter || this.state.queryParam) {
            route = '/projects?'+filter+'&limit='+this.state.perPage+'&page='+page+sort+search;
        }else if(search) {
            route = '/projects?limit='+this.state.perPage+sort+search;
        }else {
            route = '/projects?limit='+this.state.perPage+'&page='+page+sort+search;
        }

        Api.get(route).then(resp => {
            if (this._isMounted) {

                if (resp.data) {

                    this.setState({
                        rows: resp.data,
                        perPage: resp.meta.pagination.per_page,
                        currentPage: resp.meta.pagination.current_page,
                        currentPageTable: resp.meta.pagination.current_page - 1,
                        total: resp.meta.pagination.total,
                        loading: false,
                        loadingButton: false,
                        showDialogFilter: false,
                        isEdit: resp.meta.custom.isEdit,
                        isDelete: resp.meta.custom.isDelete,
                        isCreate: resp.meta.custom.isCreate,
                    })

                }
            }
        }).catch(err => {
            console.log(err);
        });
    };

    handleSearch = () => {
        this.__fetchData(false);
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.__fetchData(false);
        }
    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;
        let sortStatus = '';

        if(val === 'projectStatusBar'){
            sortStatus = 'projectStatusBar';
        }else if(val !== this.state.orderBy){
            sortedBy = 'asc';
        }else{
            if(sortedBy && sortedBy === 'asc' ){
                sortedBy = 'desc';
            }else{
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderByStatus: sortStatus,
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChange (e, prop){
        this.setState({ [prop]: e.target.value });
    };

    handleChangeSelect (e, prop){
        this.setState({ [prop]: e });
    };

    handleChangeClient (e, prop){
        this.setState({ [prop]: e });
    };

    handleDate (date, prop){
        this.setState({ [prop]: date });
    };

    handleOpen = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.name,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleOpenFilter = () => {
        this.setState({
            showDialogFilter :true,
        })
    };

    handleCloseFilter = () => {
        this.setState({
            showDialogFilter :false,
        })
    };

    handleSubmitFilter = () => {

        this.__fetchData(false);

    };

    handleExport = () => {

        this._isMounted = true;

        this.setState({
            loadingButton: true,
        })

        let page = this.state.currentPage;

        let params = {
            case_number : this.state.case_number,
            project_number : this.state.project_number,
            ext_project_number : this.state.ext_project_number,
            vessel_name : this.state.vessel_name,
            vessel_type_id : this.state.vessel_type ? this.state.vessel_type.value : '',
            client_type : this.state.client_type,
            casualty: this.state.casualty,
            casualty_type_id: this.state.casualty_type ? this.state.casualty_type.value : '',
            client_id: this.state.client_id ? this.state.client_id.value : '',
            typeId: this.state.typeId ? this.state.typeId : '',
            remarks: this.state.remarks ? this.state.remarks : '',
            projectStatusReportId: this.state.projectStatusReportId,
            projectStatusApprovalId: this.state.projectStatusApprovalId,
            case_status_id: this.state.case_status_id,
            projectStatus: this.state.projectStatus,
            surveyorId: this.state.surveyorId,
            adminId: this.state.adminId,
            finalReviewerId: this.state.finalReviewerId,
            survey_status_id: this.state.surveyId,
            start_instruction_date: this.state.start_instruction_date ? moment(this.state.start_instruction_date).startOf('year').format('YYYY-MM-DD') : '',
            end_instruction_date: this.state.end_instruction_date ? moment(this.state.end_instruction_date).endOf('year').format('YYYY-MM-DD') : '',
        };

        let sort = this.state.orderBy ? '&orderBy=' + this.state.orderBy + '&sortedBy=' + this.state.sortedBy : '';
        let search = this.state.searchBy ? '&search=' + this.state.searchBy : '';
        let filter = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let route = '';

        if(this.state.showDialogFilter || this.state.queryParam) {
            route = '/invoices?export=operation&'+filter+'&limit='+this.state.perPage+'&page='+page+sort+search;
        }else if(search) {
            route = '/invoices?export=operation&limit='+this.state.perPage+sort+search;
        }else {
            route = '/invoices?export=operation&limit='+this.state.perPage+'&page='+page+sort+search;
        }

        // if (this.state.showDialogFilter || this.state.queryParam) {
        //     route = '/invoices?export=operation&'+filter+'&limit=0'+sort+search;
        // } else if (search) {
        //     route = '/invoices?export=operation&limit=0'+sort+search;
        // } else {
        //     route = '/invoices?export=operation&limit=0'+sort;
        // }

        Api.get(route).then(resp => {
            if (this._isMounted) {

                if (resp.data) {
                    let url = resp.data.url;
                    window.open(url);
                    this.setState({
                        url: resp.data && resp.data.url,
                        loadingButton: false,
                    })

                }
            }
        }).catch(err => {
            console.log(err);
            this.setState({
                loadingButton: false,
            })
        });

    };

    handleResetFilter = () => {

        this.setState({
            case_number : '',
            project_number : '',
            ext_project_number : '',
            vessel_name : '',
            vessel_type_id: '',
            casualty: '',
            casualty_type_id: '',
            casualty_type: null,
            client_id: '',
            projectStatusReportId: '',
            projectStatusApprovalId: '',
            case_status_id: '',
            projectStatus: '',
            surveyorId: '',
            surveyId: '',
            typeId: '',
            detail_type_id: '',
            vessel_type: null,
            estimate_cost: '',
            place_of_repair: '',
            vessel_grt: '',
            vessel_yob: '',
            place_survey: '',
            start_instruction_date: null,
            end_instruction_date: null,
        },() => {
            this.__fetchData(false);
        });

    };

    handleDelete = () => {

        if(this.state.idDelete) {
            Api.delete('/projects/'+this.state.idDelete, '', false).then(resp => {

                this.setState({
                    showDialog: false,
                    }
                );

                this.showMessage(true, 'Project successfully deleted');
                this.__fetchData(false);

            }).catch(err => {

                this.setState({
                    showDialog: false
                    }
                );

                this.showMessage(true, 'Project successfully deleted');
                this.__fetchData(false);
            });
        }
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };

    handleGo  = (link) => {

        history.push(link);
    };

    filterStatus = (name) => {
        let text = '';

        switch(name) {
            case 'Follow Up':
                text = 'yellow';
                break;
            case 'On Progress':
                text = 'blue';
                break;
            case 'on progress':
                text = 'blue';
                break;
            case 'Finish':
                text = 'purple';
                break;
            case 'Completed':
                text = 'green';
                break;
            case 'Cancel':
                text = 'red';
                break;
            case 'Pending':
                text = 'yellow';
                break;
            case 'Partial Payment':
                text = 'green';
                break;
            case 'Complete Payment':
                text = 'blue';
                break;
            default:
                text = 'yellow';
        }

        return text;
    };

    render() {
        return (
            <div className='row main-content'>
                <div className='col-12 px-lg-5'>
                    <h1 className='page-title'>Projects</h1>
                    <nav aria-label='breadcrumb'>
                        <ol className='breadcrumb'>
                            <li className='breadcrumb-item'><Link to='/' >Home</Link></li>
                            <li className='breadcrumb-item active' aria-current='page'>Projects</li>
                        </ol>
                    </nav>

                </div>
                <div className='col-12 mt-3 px-lg-5'>
                    <div className='table-wrapper'>
                        <div className='row align-items-center mb-md-3'>
                            <div className='col-md-8'>
                                <TextField
                                    id='input-with-icon-textfield'
                                    variant='outlined'
                                    className='search-field'
                                    onChange={(e) => this.handleChange(e, 'searchBy')}
                                    onKeyDown={this.handleKeyDown}
                                    onBlur={this.handleSearch}
                                    placeholder='Search here'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='start'>
                                                <IconButton
                                                    aria-label='Search click'
                                                    onClick={this.handleSearch}
                                                >
                                                    <i className='fas fa-search'> </i>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    variant='contained'
                                    color='primary'
                                    className='ml-3 round'
                                    onClick = {() => this.handleOpenFilter()}
                                >
                                    <TuneIcon />
                                    Filters
                                </Button>
                            </div>
                            <div className='col-md-4 text-md-right'>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    className='mb-3 mb-md-0 mr-2'
                                    onClick={() => this.handleGo('/projects/add')}
                                >
                                    Create Project
                                </Button>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    className='mb-3 mb-md-0'
                                    onClick={() => this.handleExport()}
                                >
                                    Export { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                            </div>
                        </div>
                        <TableContainer>
                            <Table stickyHeader className='table-list' size='small' >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='clickable' onClick={() => this.handleSort('case_number')} >Case No
                                            {this.state.orderBy === 'case_number' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell style={styleWidth1}>Underwritter</TableCell>
                                        <TableCell className='clickable' onClick={() => this.handleSort('type_id')} >Project Type
                                            {this.state.orderBy === 'type_id' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell className='clickable' onClick={() => this.handleSort('vessel_name')} >Vessel
                                            {this.state.orderBy === 'vessel_name' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell style={styleMax} className='clickable' onClick={() => this.handleSort('casualty')} >Casualties
                                            {this.state.orderBy === 'casualty' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell style={styleWidth1} className='clickable' onClick={() => this.handleSort('date_survey_status')} >Survey Status
                                            {this.state.orderBy === 'date_survey_status' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell style={styleWidth} className='clickable' onClick={() => this.handleSort('date_status')} >Report/Approval Status
                                            {this.state.orderBy === 'date_status' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell style={styleWidth} className='clickable'>Project Status</TableCell>
                                        <TableCell style={styleMax} className='clickable' onClick={() => this.handleSort('updated_at')} >Last Status
                                            {this.state.orderBy === 'updated_at' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell >Days
                                        </TableCell>
                                        <TableCell width={120} style={styleWidth}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loading ? (
                                        <TableRow>
                                            <TableCell colSpan={11} align='center' className='py-5'>
                                                <CircularProgress color='primary'  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.length === 0 ? (
                                            <TableRow style={{ height: 33 * this.state.perPage}}>
                                                <TableCell colSpan={11} style={{ textAlign: 'center' }}>No project</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.rows.map(row => (
                                                <TableRow
                                                    key={row.id}
                                                    selected={row.selected}>
                                                    <TableCell>
                                                        <Link to={'/projects/detail/' + row.id} target='_blank'>
                                                            {row.caseNumber === null ? '' : row.caseNumber}{row.projectNumber === null ? '' : '['+row.projectNumber+']'}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.underWriters &&
                                                        row.underWriters.map(row => (
                                                            <small key={row.id}>{row.name}, </small>
                                                            ))
                                                        }
                                                     </TableCell>
                                                    <TableCell>{row.type === null ? '' : row.type.name}{row.detailType === null ? '' : '['+row.detailType.name+']'}</TableCell>
                                                    <TableCell>{row.vesselName === null ? '' : row.vesselName}</TableCell>
                                                    <TableCell>{row.casualty === null ? '' : row.casualty}</TableCell>
                                                    <TableCell>
                                                        {(row.surveyStatus && row.type.name === 'Report') &&
                                                        <small>
                                                            <span className={'label-status label-'+this.filterStatus(row.surveyStatus !== null && row.surveyStatus.project_status)}>{row.surveyStatus === null ? '' : row.surveyStatus.name}</span>
                                                            {row.dateSurveyStatus === null ? '' : moment(row.dateSurveyStatus).format('DD MMM YYYY')}
                                                        </small>
                                                        }
                                                        </TableCell>
                                                    <TableCell>

                                                        {(row.projectStatus && row.surveyStatus && row.surveyStatus.name !== 'Waiting for Survey Arrangement') &&
                                                        <small>
                                                            <span className={'label-status label-'+this.filterStatus(row.projectStatus !== null && row.projectStatus.project_status)}>{row.projectStatus === null ? '' : row.projectStatus.name}</span>
                                                            {row.dateStatus === null ? '' : moment(row.dateStatus).format('DD MMM YYYY')}
                                                        </small>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.projectStatusBar &&
                                                        <small>
                                                            <span className={'label-status label-'+this.filterStatus(row.projectStatusBar !== null && row.projectStatusBar.project_status)}>{row.projectStatusBar === null ? '' : row.projectStatusBar.project_status}</span>
                                                        </small>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {(row.projectStatus && row.projectStatus.project_status !== 'Finish') ?
                                                            <span className={moment().diff(row.comment.length > 0 && row.comment[0].updated_at, 'day') > 7 ? 'alert w-100' : ''}>
                                                                {row.comment === null ? '' : row.comment.length > 0 && row.comment[0].comment.substring(0, 50)}
                                                            </span>
                                                            : row.comment === null ? '' : row.comment.length > 0 && row.comment[0].comment.substring(0, 50)
                                                        }
                                                        <small className='d-block'>{row.comment === null ? '' : row.comment.length > 0 && moment(row.comment[0].updated_at).format('DD MMM YYYY')}</small>
                                                    </TableCell>


                                                    <TableCell> {row.numberOfDays > 30 ?
                                                        <span className='danger'>{row.numberOfDays}</span> : row.numberOfDays }</TableCell>
                                                    <TableCell>
                                                        <div className="d-flex">
                                                            <Link to={'/projects/detail/' + row.id} className='btn-icon mr-2'>
                                                                <i className='fas fa-eye'> </i>
                                                            </Link>
                                                            <Link to={'/projects/edit/' + row.id} className='btn-icon mr-2'>
                                                                <i className='fas fa-edit'> </i>
                                                            </Link>
                                                            <button className='btn-icon' onClick={() => this.handleOpen(row)}>
                                                                <i className='fas fa-trash-alt'> </i>
                                                            </button>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )
                                    )}

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component='div'
                        count={this.state.total}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPageTable}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />

                </div>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>DELETE PROJECT: {this.state.currDelete}</DialogTitle>
                    <DialogContent>
                        <p>Are you sure you wanna delete this project?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleDelete}
                            disabled={this.state.loadingButton && 'disabled' }
                        >
                            Delete{ this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i> }
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth='md'
                    fullWidth={true}
                    open={this.state.showDialogFilter}
                    onClose={this.handleCloseFilter}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>Filter</DialogTitle>
                    <DialogContent>
                        <form name='filter' id='filterProject' className='row' noValidate>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Case No</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='case_number'
                                               name='case_number'
                                               label='Case No'
                                               onChange={(e) => this.handleChange(e, 'case_number')}
                                               value={this.state.case_number}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.case_number}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Case Type</label>
                                    <TextField variant="outlined"
                                               select
                                               id="case_type"
                                               name="case_type"
                                               label="Case Type"
                                               onChange={(e) => this.handleChange(e, 'case_type')}
                                               value={this.state.case_type}
                                               fullWidth
                                    >
                                        <MenuItem value="H%26M">H&M</MenuItem>
                                        <MenuItem value="P%26I">P&I</MenuItem>
                                        <MenuItem value="Shipowner">Shipowner</MenuItem>
                                        <MenuItem value='Cargo'>Cargo</MenuItem>
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.case_type}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Project No</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='project_number'
                                               name='project_number'
                                               label='Project No'
                                               onChange={(e) => this.handleChange(e, 'project_number')}
                                               value={this.state.project_number}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.project_number}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Ext Project Number</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='ext_project_number'
                                               name='ext_project_number'
                                               label='Ext Project Number'
                                               onChange={(e) => this.handleChange(e, 'ext_project_number')}
                                               value={this.state.ext_project_number}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.ext_project_number}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Vessel Name</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='vessel_name'
                                               name='vessel_name'
                                               label='Vessel Name'
                                               onChange={(e) => this.handleChange(e, 'vessel_name')}
                                               value={this.state.vessel_name}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.vessel_name}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Vessel Type</label>
                                    <Select
                                        options = {this.state.vesselTypes}
                                        onChange={(e) => this.handleChangeSelect(e, 'vessel_type')}
                                        value={this.state.vessel_type}
                                        isClearable
                                    />
                                    <div className='text-danger'>{this.state.errors.vessel_type}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Casualty</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='casualty'
                                               name='casualty'
                                               label='Casualty'
                                               onChange={(e) => this.handleChange(e, 'casualty')}
                                               value={this.state.casualty}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.casualty}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Casualty Type</label>
                                    <Select
                                        options = {this.state.casualtyTypes}
                                        onChange={(e) => this.handleChangeSelect(e, 'casualty_type')}
                                        value={this.state.casualty_type}
                                        isClearable
                                    />
                                    <div className='text-danger'>{this.state.errors.casualty_type}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Client Type</label>
                                    <TextField variant="outlined"
                                               select
                                               id="client_type"
                                               name="client_type"
                                               label="Type"
                                               onChange={(e) => this.handleChange(e, 'client_type')}
                                               value={this.state.client_type}
                                               fullWidth
                                    >
                                        <MenuItem value="Underwriter">
                                            Underwriter
                                        </MenuItem>
                                        <MenuItem value="Broker">
                                            Broker
                                        </MenuItem>
                                        <MenuItem value="Shipowner">
                                            Shipowner
                                        </MenuItem>
                                        <MenuItem value="Adjuster">
                                            Adjuster
                                        </MenuItem>
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.client_type}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Client</label>
                                    <Select options = {this.state.clients}
                                            onChange={(e) => this.handleChangeClient(e,'client_id')}
                                            value={this.state.client_id}
                                    />
                                    <div className='text-danger'>{this.state.errors.client_id}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Report Status</label>
                                    <TextField variant='outlined'
                                               select
                                               id='projectStatusReportId'
                                               name='projectStatusReportId'
                                               label='Report Status'
                                               onChange={(e) => this.handleChange(e, 'projectStatusReportId')}
                                               value={this.state.projectStatusReportId}
                                               fullWidth
                                    >
                                        {this.state.report_status.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.projectStatusReportId}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Approval Status</label>
                                    <TextField variant='outlined'
                                               select
                                               id='projectStatusApprovalId'
                                               name='projectStatusApprovalId'
                                               label='Approval Status'
                                               onChange={(e) => this.handleChange(e, 'projectStatusApprovalId')}
                                               value={this.state.projectStatusApprovalId}
                                               fullWidth
                                    >
                                        {this.state.approval_status.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.projectStatusApprovalId}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Case Status</label>
                                    <TextField 
                                        variant='outlined'
                                        select
                                        id='case_status_id'
                                        name='case_status_id'
                                        label='Case Status'
                                        onChange={(e) => this.handleChange(e, 'case_status_id')}
                                        value={this.state.case_status_id}
                                        fullWidth
                                    >
                                        {this.state.case_status.map(option => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))}
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.case_status_id}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Survey Status</label>
                                    <TextField variant='outlined'
                                               select
                                               id='surveyId'
                                               name='surveyId'
                                               label='Survey Status'
                                               onChange={(e) => this.handleChange(e, 'surveyId')}
                                               value={this.state.surveyId}
                                               fullWidth
                                    >
                                        {this.state.survey_status.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.surveyId}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Project Status</label>
                                    <TextField variant='outlined'
                                               select
                                               id='projectStatus'
                                               name='projectStatus'
                                               label='Project Status'
                                               onChange={(e) => this.handleChange(e, 'projectStatus')}
                                               value={this.state.projectStatus}
                                               fullWidth
                                    >
                                        <MenuItem key='1' value='Follow Up'>Follow Up</MenuItem>
                                        <MenuItem key='2' value='On Progress'>On Progress</MenuItem>
                                        <MenuItem key='3' value='Finish'>Finish</MenuItem>
                                        <MenuItem key='4' value='Completed'>Completed</MenuItem>
                                        <MenuItem key='5' value='Cancel'>Cancel</MenuItem>
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.projectStatus}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Project Type</label>
                                    <TextField variant='outlined'
                                               select
                                               id='typeId'
                                               name='typeId'
                                               label='Project Type'
                                               onChange={(e) => this.handleChange(e, 'typeId')}
                                               value={this.state.typeId}
                                               fullWidth
                                    >
                                        {this.state.types.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.typeId}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Project Type Detail</label>
                                    <TextField 
                                        variant='outlined'
                                        select
                                        id='detail_type_id'
                                        name='detail_type_id'
                                        label='Project Type'
                                        onChange={(e) => this.handleChange(e, 'detail_type_id')}
                                        value={this.state.detail_type_id}
                                        fullWidth
                                    >
                                        {this.state.detailTypes.map(option => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))}
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.detail_type_id}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Surveyor</label>
                                    <TextField variant='outlined'
                                               select
                                               id='surveyorId'
                                               name='surveyorId'
                                               label='Surveyor'
                                               onChange={(e) => this.handleChange(e, 'surveyorId')}
                                               value={this.state.surveyorId}
                                               fullWidth
                                    >
                                        {this.state.surveyors.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.surveyorId}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Admin</label>
                                    <TextField variant='outlined'
                                               select
                                               id='adminId'
                                               name='adminId'
                                               label='Admin'
                                               onChange={(e) => this.handleChange(e, 'adminId')}
                                               value={this.state.adminId}
                                               fullWidth
                                    >
                                        {this.state.admins.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.surveyorId}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Final Reviewer</label>
                                    <TextField
                                        variant='outlined'
                                        select
                                        id='finalReviewerId'
                                        name='finalReviewerId'
                                        label='Final Reviewer'
                                        onChange={(e) => this.handleChange(e, 'finalReviewerId')}
                                        value={this.state.finalReviewerId}
                                        fullWidth
                                    >
                                        {this.state.finalReviewers.map(option => (
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        ))}
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.surveyorId}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Remarks</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='remarks'
                                               name='remarks'
                                               label='Remarks'
                                               onChange={(e) => this.handleChange(e, 'remarks')}
                                               value={this.state.remarks}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.remarks}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Vessel GRT</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='vessel_grt'
                                               name='vessel_grt'
                                               label='Vessel GRT'
                                               onChange={(e) => this.handleChange(e, 'vessel_grt')}
                                               value={this.state.vessel_grt}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.vessel_grt}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Vessel YOB</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='vessel_yob'
                                               name='vessel_yob'
                                               label='Vessel YOB'
                                               onChange={(e) => this.handleChange(e, 'vessel_yob')}
                                               value={this.state.vessel_yob}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.vessel_yob}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Place of Survey</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='place_survey'
                                               name='place_survey'
                                               label='Place of Survey'
                                               onChange={(e) => this.handleChange(e, 'place_survey')}
                                               value={this.state.place_survey}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.place_survey}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Estimation Cost of Repair</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='estimate_cost'
                                               name='estimate_cost'
                                               label='Estimation Cost of Repair'
                                               onChange={(e) => this.handleChange(e, 'estimate_cost')}
                                               value={this.state.estimate_cost}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.estimate_cost}</div>
                                </div>
                            </div>
                            {/* <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Place of Repair / Shipyard Location</label>
                                    <TextField 
                                        variant='outlined'
                                        type='text'
                                        id='place_of_repair'
                                        name='place_of_repair'
                                        label='Place of Repair / Shipyard Location'
                                        onChange={(e) => this.handleChange(e, 'place_of_repair')}
                                        value={this.state.place_of_repair}
                                        fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.place_of_repair}</div>
                                </div>
                            </div> */}
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Instruction Date</label>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    label='From Year'
                                                    value={this.state.start_instruction_date}
                                                    onChange={(date) => this.handleDate(date, 'start_instruction_date')}
                                                    format={'yyyy'}
                                                    cancelLabel='BATAL'
                                                    inputVariant='outlined'
                                                    minDate={moment().year(2000)}
                                                    maxDate={this.state.end_instruction_date || moment()}
                                                    views={["year"]}
                                                    animateYearScrolling
                                                    autoOk
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div className='text-danger'>{this.state.errors.start_instruction_date}</div>
                                        </div>
                                        <div className='col-md-6'>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    label='To Year'
                                                    value={this.state.end_instruction_date}
                                                    onChange={(date) => this.handleDate(date, 'end_instruction_date')}
                                                    format={'yyyy'}
                                                    cancelLabel='BATAL'
                                                    inputVariant='outlined'
                                                    minDate={this.state.start_instruction_date || moment().year(2000)}
                                                    maxDate={moment()}
                                                    views={["year"]}
                                                    animateYearScrolling
                                                    autoOk
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div className='text-danger'>{this.state.errors.end_instruction_date}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </DialogContent>
                    <DialogActions className='justify-content-center pb-3'>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleResetFilter}
                        >
                            Reset
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleSubmitFilter}
                            disabled={this.state.loadingButton && true }
                        >
                            Submit{ this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i> }
                        </Button>
                    </DialogActions>
                </Dialog>

                <ToastContainer autoClose={3000} />

            </div>
        )
    }
}

export default Projects;
