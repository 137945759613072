import React, { Component } from 'react';
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import SimpleReactValidator from 'simple-react-validator';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';

import Select from 'react-select';
import { AsyncPaginate as SelectPaginate } from 'react-select-async-paginate';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import Api from '../../../libraries/api';
import ErrorHandling from '../../../libraries/error-handling';
import {history} from '../../../shared/configure-store';


require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validatorObj = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class ProjectsAdd extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            types: [],
            detailTypes: [],
            approvalCategories: [],
            underwriters: [],
            brokers: [],
            adjusters: [],
            shipowners: [],
            vesselTypes: [],
            casualtyTypes: [],

            case_number: '',
            project_number: '',
            ext_project_number: '',
            case_type: '',
            type_id: '',
            detail_type_id: '',
            approval_category_id: '',
            vessel_name: '',
            vessel_type: '',
            vessel_grt: '',
            vessel_yob: '',
            engine_particular_hp: '',
            broker: '',
            adjuster: '',
            shipowner: '',
            underwriter: [],
            casualty: '',
            casualty_type: '',
            place_of_accident: '',
            instruction_date: null,
            date_of_accident: null,
            instruction_by: '',
            pic_case: '',
            currency: '',
            currency_rate: '',
            instructor: '',

            surveyor: [],
            admins: [],
            under: '',
            soft_copy_data: 0,
            hard_copy_report: 0,
            hard_copy_invoice: 0,


        };

        this.handleChange = this.handleChange.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleDel = this.handleDel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = 'Abadi - Add Project';

        Api.get('/types').then(resp => {
            if (resp.data) this.setState({ types: resp.data });
        });

        Api.get('/detailtypes').then(resp => {
            if (resp.data) this.setState({ detailTypes: resp.data });
        });

        Api.get('/approval_categories').then(resp => {
            if (resp.data) this.setState({ approvalCategories: resp.data });
        });

        Api.get('/clients?type=Underwriter&limit=0').then(resp => {
            if (resp.data) {
                let data = resp.data.map((o) => ({ label: o.name, value: o.id }));
                this.setState({ underwriters: data });
            }
        });

        Api.get('/clients?type=Broker&limit=0').then(resp => {
            if (resp.data) {
                let data = resp.data.map((o) => ({ label: o.name, value: o.id }));
                this.setState({ brokers: data });
            }
        });

        Api.get('/clients?type=Adjuster&limit=0').then(resp => {
            if (resp.data) {
                let data = resp.data.map((o) => ({ label: o.name, value: o.id }));
                this.setState({ adjusters: data });
            }
        });

        Api.get('/clients?type=Shipowner&limit=0').then(resp => {
            if (resp.data) {
                let data = resp.data.map((o) => ({ label: o.name, value: o.id }));
                this.setState({ shipowners: data });
            }
        });

        Api.get('/vessel-type').then(resp => {
            if (resp.data) {
                let data = resp.data.map((o) => ({ label: o.name, value: o.id }));
                this.setState({ vesselTypes: data });
            }
        });

        Api.get('/casualty-type').then(resp => {
            if (resp.data) {
                let data = resp.data.map((o) => ({ label: o.name, value: o.id }));
                this.setState({ casualtyTypes: data });
            }
        });
    }

    loadOptionsInstructor = async (search, _loadedOptions, { page }) => {
        let data = [];

        await Api.get(`/clients?search=${search}&limit=10&page=${page}`).then(resp => {
            if (resp?.data?.length > 0) data = resp.data.map((item)=> ({ label: item.name, value: item.id }));
        });

        return {
            options: data,
            hasMore: data.length > 0 ? true : false,
            additional: { page: page + 1 },
        };
    };

    handleType (e, prop){
        Api.get('/detailtypes?search=type_id:'+e.target.value).then(resp => {
            if (resp.data) this.setState({ detailTypes: resp.data });
        });
        this.setState({ [prop]: e.target.value  });
    };

    handleChange (e, prop){
        this.setState({ [prop]: e.target.value });
    };

    handleChangeSelect (e, prop){
        this.setState({ [prop]: e });
    };

    handleChangeNumber = (value, prop) => {
        this.setState({ [prop]: value });
    };

    handleChangeClient (e, prop){
        this.setState({ [prop]: e });
    };

    handleDate (date, prop){
        this.setState({ [prop]: date });
    };

    handleChecked = name => e => {
        this.setState({ [name]: e.target.checked ? 1 : 0 });
    };

    showMessage = (status, message) => {
        if (status) toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
        else toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (!validator.allValid()) {
            this.setState({ errors: validator.getErrorMessages() });
            return false;
        }

        this.setState({ errors: {}, loadingButton: true });

        let temp = [];
        _.forEach(this.state.underwriter, function(v){
            temp.push(v.id)
        });

        let params = {
            case_number : this.state.case_number,
            project_number : this.state.project_number,
            ext_project_number : this.state.ext_project_number,
            case_type : this.state.case_type,
            type_id : this.state.type_id,
            detail_type_id : this.state.detail_type_id,
            approval_category_id : this.state.approval_category_id,
            vessel_name : this.state.vessel_name,
            vessel_type_id : this.state.vessel_type.value,
            vessel_grt : this.state?.vessel_grt ? Number(this.state.vessel_grt) : null,
            vessel_yob : this.state.vessel_yob,
            engine_particular_hp : this.state.engine_particular_hp,
            broker : this.state.broker.value,
            adjuster : this.state.adjuster.value,
            shipowner : this.state.shipowner.value,
            underwriter : temp,
            casualty: this.state.casualty,
            casualty_type_id : this.state.casualty_type.value,
            place_of_accident: this.state.place_of_accident,
            instruction_date: this.state.instruction_date === null ? null : moment(this.state.instruction_date).format('YYYY-MM-DD'),
            date_of_accident: this.state.date_of_accident === null ? null : moment(this.state.date_of_accident).format('YYYY-MM-DD'),
            instruction_by: this.state.instruction_by,
            pic_case: this.state.pic_case,
            instructor: this.state.instructor?.value,
        };

        Api.post('/project/create', params, false).then(resp => {
            this.setState({ loadingButton: false });
            history.push('/projects');
            this.showMessage(true, 'Project added');
        }).catch(err => {
            if (ErrorHandling.checkErrorTokenExpired(err)){

            } else {
                this.setState({ errors: err.errors, loadingButton: false });
                this.showMessage(false, 'Invalid format data');
            }
        });
    };

    handleAdd = (e) => {
        e.preventDefault();

        if (!validatorObj.allValid()) {
            this.setState({ errors: validatorObj.getErrorMessages() });
            return false;
        }

        this.setState({ errors: {} });

        let params = {
            id : this.state.under.value,
            name : this.state.under.label,
        };

        this.state.underwriter.push(params);
        this.setState({ id: '', name: '' });
    };

    handleDel(id) {
        let data = this.state.underwriter.filter((item) => item.id !== id);
        this.setState({ underwriter: data });
    };

    handleDelSelect(prop) {
        this.setState({ [prop]: '' });
    };

    handleDelDate(prop) {
        this.setState({ [prop]: null });
    };

    filterName = (id) => {
        let all = this.state.underwriters.filter((item) => item.id === id);
        return all[0].name;
    };

    handleGo  = (link) => {
        history.push(link);
    };

    handleSearchCase = () => {
        if(this.state.case_number) {
            let route = '/projects?case_number='+this.state.case_number+'&limit=0';

            Api.get(route).then(resp => {
                if (resp.data) {
                    if(resp.data.length > 0) {
                        let data = resp.data[0];
                        let typeProject = '';
                        if(data.underWriters.length > 0) {
                            _.forEach(data.underWriters, function(v){
                                v.id = v.clients_id;
                            });
                        }

                        if(data.type) {
                            if(data.type.name === "Report") {
                                typeProject = 'adm-report';
                            }else if(data.type.name === "Approval"){
                                typeProject = 'adm-approval';
                            }

                            Api.get('/users?additional='+typeProject+'&limit=0').then(resp => {
                                if (resp.data) {
                                    this.setState({ adminslist: resp.data });
                                }
                            }).catch(err => {
                                console.log(err);
                            });
                        }

                        this.setState({
                            case_number: data.caseNumber ? data.caseNumber : '',
                            project_number: data.projectNumber ? data.projectNumber : '',
                            ext_project_number: data.extProjectNumber ? data.extProjectNumber : '',
                            case_type: data.caseType ? data.caseType : '',
                            type_id: data.type ? data.type.id : '',
                            detail_type_id: data.detailType ? data.detailType.id :'',
                            approval_category_id: data.approvalCategory ? data.approvalCategory.id :'',
                            vessel_name: data.vesselName ? data.vesselName : '',
                            vessel_type: data.vesselType ? data.vesselType : '',
                            vessel_grt: data.vesselGrt ? data.vesselGrt : '',
                            vessel_yob: data.vesselYob ? data.vesselYob : '',
                            engine_particular_hp: data?.engineParticularHp ? data.engineParticularHp : '',
                            broker: data.broker ? data.broker.id : '',
                            adjuster: data.adjuster ? data.adjuster.id : '',
                            shipowner: data.shipowner ? data.shipowner.id : '',
                            underwriter: data.underWriters ? data.underWriters : [],
                            casualty: data.casualty ? data.casualty : '',
                            place_of_accident: data.placeOfAccident ? data.placeOfAccident : '',
                            instruction_date: data.instructionDate ? data.instructionDate : null,
                            date_of_accident: data.dateOfAccident ? data.dateOfAccident : null,
                            instruction_by: data.instructionBy ? data.instructionBy : '',
                            pic_case: data.picCase ? data.picCase : '',
                        })
                        this.showMessage(true, 'Case found');
                    } else {
                        this.showMessage(false, 'Case not found');
                        this.setState({
                            case_number: '',
                            project_number: '',
                            ext_project_number: '',
                            case_type: '',
                            type_id: '',
                            detail_type_id: '',
                            approval_category_id: '',
                            vessel_name: '',
                            vessel_type: '',
                            vessel_grt: '',
                            vessel_yob: '',
                            engine_particular_hp: '',
                            broker: '',
                            adjuster: '',
                            shipowner: '',
                            underwriter: [],
                            casualty: '',
                            place_of_accident: '',
                            instruction_date: null,
                            date_of_accident: null,
                            instruction_by: '',
                            pic_case: '',
                        });
                    }
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            this.showMessage(false, 'Case number empty');
        }

    };

    render() {
        return (
            <div className='row main-content'>
                <div className='col-12 px-lg-5'>
                    <h1 className='page-title'>Create Project</h1>
                    <nav aria-label='breadcrumb'>
                        <ol className='breadcrumb'>
                            <li className='breadcrumb-item'><Link to='/' >Home</Link></li>
                            <li className='breadcrumb-item'><Link to='/projects' >Project</Link></li>
                            <li className='breadcrumb-item active' aria-current='page'>Create</li>
                        </ol>
                    </nav>
                </div>
                <div className='col-md-9 mt-3 pl-lg-5'>
                    <div className='table-wrapper'>
                        <form name='add' id='addType' className='row' noValidate>
                            <div className='col-12'>
                                <div className='group-title'>
                                    <h3>Internal Data</h3>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Case No.</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='case_number'
                                               name='case_number'
                                               label='Case No.'
                                               onChange={(e) => this.handleChange(e, 'case_number')}
                                               value={this.state.case_number}
                                               fullWidth
                                               InputProps={{
                                                   endAdornment: (
                                                       <InputAdornment position='end'>
                                                           <IconButton
                                                               className='btn-input'
                                                               aria-label='Search visibility'
                                                               onClick={this.handleSearchCase}
                                                           >
                                                               <i className='fas fa-search'> </i>
                                                           </IconButton>
                                                       </InputAdornment>
                                                   )
                                               }}
                                    />
                                    {validator.message('case_number', this.state.case_number, 'required')}
                                    <div className='text-danger'>{this.state.errors.case_number}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Project No.</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='project_number'
                                               name='project_number'
                                               label='Project No.'
                                               onChange={(e) => this.handleChange(e, 'project_number')}
                                               value={this.state.project_number}
                                               fullWidth
                                    />
                                    {validator.message('project_number', this.state.project_number, 'required')}
                                    <div className='text-danger'>{this.state.errors.project_number}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Ext Project No.</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='ext_project_number'
                                               name='ext_project_number'
                                               label='Ext Project No.'
                                               onChange={(e) => this.handleChange(e, 'ext_project_number')}
                                               value={this.state.ext_project_number}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.ext_project_number}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Case Type</label>
                                    <TextField variant='outlined'
                                               select
                                               id='case_type'
                                               name='case_type'
                                               label='Case Type'
                                               onChange={(e) => this.handleChange(e, 'case_type')}
                                               value={this.state.case_type}
                                               fullWidth
                                    >
                                        <MenuItem value='H&M'>H&M</MenuItem>
                                        <MenuItem value='P&I'>P&I</MenuItem>
                                        <MenuItem value='Shipowner'>Shipowner</MenuItem>
                                        <MenuItem value='Cargo'>Cargo</MenuItem>
                                    </TextField>
                                    {validator.message('case_type', this.state.case_type, 'required')}
                                    <div className='text-danger'>{this.state.errors.case_type}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Project Type</label>
                                    <TextField variant='outlined'
                                               select
                                               id='type_id'
                                               name='type_id'
                                               label='Project Type'
                                               onChange={(e) => this.handleType(e, 'type_id')}
                                               value={this.state.type_id}
                                               fullWidth
                                    >
                                        {this.state.types.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {validator.message('type_id', this.state.type_id, 'required')}
                                    <div className='text-danger'>{this.state.errors.type_id}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Project Detail Type</label>
                                    <TextField variant='outlined'
                                               select
                                               id='detail_type_id'
                                               name='detail_type_id'
                                               label='Project Detail Type'
                                               onChange={(e) => this.handleChange(e, 'detail_type_id')}
                                               value={this.state.detail_type_id}
                                               fullWidth
                                    >
                                        {this.state.detailTypes.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.detail_type_id}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Approval Category</label>
                                    <TextField 
                                        variant='outlined'
                                        select
                                        id='approval_category_id'
                                        name='approval_category_id'
                                        label='Approval Category'
                                        onChange={(e) => this.handleChange(e, 'approval_category_id')}
                                        value={this.state.approval_category_id}
                                        fullWidth
                                    >
                                        {this.state.approvalCategories.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.approval_category_id}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Instructor</label>
                                    <SelectPaginate
                                        value={this.state.instructor}
                                        loadOptions={this.loadOptionsInstructor}
                                        onChange={(e) => this.handleChangeClient(e,'instructor')}
                                        additional={{ page: 1 }}
                                        debounceTimeout={500}
                                    />
                                    {this.state.instructor &&
                                        <button
                                            type='button'
                                            onClick={() => this.handleDelSelect('instructor')}
                                            className='btn btn-delete-select'><i className='fas fa-times-circle'> </i>
                                        </button>
                                    }
                                    <div className='text-danger'>{this.state.errors.instructor}</div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='group-title'>
                                    <h3>Vessel Particular</h3>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Vessel Name</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='vessel_name'
                                               name='vessel_name'
                                               label='Vessel Name'
                                               onChange={(e) => this.handleChange(e, 'vessel_name')}
                                               value={this.state.vessel_name}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.vessel_name}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Vessel Type</label>
                                    <Select
                                        options = {this.state.vesselTypes}
                                        onChange={(e) => this.handleChangeSelect(e, 'vessel_type')}
                                        value={this.state.vessel_type}
                                    />
                                    <div className='text-danger'>{this.state.errors.vessel_type}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Vessel GRT</label>
                                    <NumberFormat
                                        customInput={TextField}
                                        variant="outlined"
                                        id="vessel_grt"
                                        name="vessel_grt"
                                        label="Vessel GRT"
                                        value={this.state.vessel_grt}
                                        onValueChange={(values) => {
                                            this.handleChangeNumber(values?.value, 'vessel_grt');
                                        }}
                                        thousandSeparator
                                        isNumericString
                                        decimalScale={0}
                                        fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.vessel_grt}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Vessel YOB</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='vessel_yob'
                                               name='vessel_yob'
                                               label='Vessel YOB'
                                               onChange={(e) => this.handleChange(e, 'vessel_yob')}
                                               value={this.state.vessel_yob}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.vessel_yob}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>ENGINE Particular HP</label>
                                    <TextField 
                                        variant='outlined'
                                        type='text'
                                        id='engine_particular_hp'
                                        name='engine_particular_hp'
                                        label='ENGINE Particular HP'
                                        onChange={(e) => this.handleChange(e, 'engine_particular_hp')}
                                        value={this.state.engine_particular_hp}
                                        fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.engine_particular_hp}</div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='group-title'>
                                    <h3>Clients</h3>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Underwriter</label>
                                    <Select options = {this.state.underwriters}
                                            onChange={(e) => this.handleChangeClient(e,'under')}
                                            value={this.state.under}
                                    />
                                    {validatorObj.message('under', this.state.under, 'required')}
                                    <div className='text-danger'>{this.state.errors.under}</div>
                                    <button
                                        onClick={this.handleAdd}
                                        className='btn-link'
                                    >
                                        <AddCircleIcon /> Add Underwriter
                                    </button>
                                    {
                                        this.state.underwriter.length > 0 &&
                                        (<div className='card-temp mt-3'>
                                                {
                                                    this.state.underwriter.map(function (objectKey, index) {
                                                        return <div className='list-temp' key={index}>
                                                            <button
                                                                type='button'
                                                                onClick={() => this.handleDel(objectKey.id)}
                                                                className='btn-row min'><i className='fas fa-times-circle'> </i>
                                                            </button>
                                                            <span>{objectKey.name}</span>
                                                        </div>
                                                    }.bind(this))
                                                }
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Broker</label>
                                        <Select options = {this.state.brokers}
                                                onChange={(e) => this.handleChangeClient(e,'broker')}
                                                value={this.state.broker}
                                        />
                                    {this.state.broker &&
                                    <button
                                        type='button'
                                        onClick={() => this.handleDelSelect('broker')}
                                        className='btn btn-delete-select'><i className='fas fa-times-circle'> </i>
                                    </button>
                                    }
                                    <div className='text-danger'>{this.state.errors.broker}</div>
                                </div>
                                <div className='form-group'>
                                    <label>Adjuster</label>
                                    <Select options = {this.state.adjusters}
                                            onChange={(e) => this.handleChangeClient(e,'adjuster')}
                                            value={this.state.adjuster}
                                    />
                                    {this.state.adjuster &&
                                    <button
                                        type='button'
                                        onClick={() => this.handleDelSelect('adjuster')}
                                        className='btn btn-delete-select'><i className='fas fa-times-circle'> </i>
                                    </button>
                                    }
                                    <div className='text-danger'>{this.state.errors.adjuster}</div>
                                </div>
                                <div className='form-group'>
                                    <label>Shipowner</label>
                                    <Select options = {this.state.shipowners}
                                            onChange={(e) => this.handleChangeClient(e,'shipowner')}
                                            value={this.state.shipowner}
                                    />
                                    {this.state.shipowner &&
                                    <button
                                        type='button'
                                        onClick={() => this.handleDelSelect('shipowner')}
                                        className='btn btn-delete-select'><i className='fas fa-times-circle'> </i>
                                    </button>
                                    }
                                    <div className='text-danger'>{this.state.errors.shipowner}</div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='group-title'>
                                    <h3>Accident</h3>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Casualties</label>
                                    <TextField 
                                        variant='outlined'
                                        type='text'
                                        id='casualty'
                                        name='casualty'
                                        label='Casualties'
                                        onChange={(e) => this.handleChange(e, 'casualty')}
                                        value={this.state.casualty}
                                        fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.casualty}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Casualty Type</label>
                                    <Select
                                        options = {this.state.casualtyTypes}
                                        onChange={(e) => this.handleChangeSelect(e, 'casualty_type')}
                                        value={this.state.casualty_type}
                                    />
                                    <div className='text-danger'>{this.state.errors.casualty_type}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Place of Accident</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='place_of_accident'
                                               name='place_of_accident'
                                               label='Place of Accident'
                                               onChange={(e) => this.handleChange(e, 'place_of_accident')}
                                               value={this.state.place_of_accident}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.place_of_accident}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Date of Accident</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            label='Date of Accident'
                                            value={this.state.date_of_accident}
                                            onChange={(date) => this.handleDate(date, 'date_of_accident')}
                                            format={'dd MMM yyyy'}
                                            cancelLabel='BATAL'
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                    {this.state.date_of_accident &&
                                    <button
                                        type='button'
                                        onClick={() => this.handleDelDate('date_of_accident')}
                                        className='btn btn-delete-select'><i className='fas fa-times-circle'> </i>
                                    </button>
                                    }
                                    <div className='text-danger'>{this.state.errors.date_of_accident}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Instruction Date</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            label='Instruction Date'
                                            value={this.state.instruction_date}
                                            onChange={(date) => this.handleDate(date, 'instruction_date')}
                                            format={'dd MMM yyyy'}
                                            cancelLabel='BATAL'
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                    {this.state.instruction_date &&
                                    <button
                                        type='button'
                                        onClick={() => this.handleDelDate('instruction_date')}
                                        className='btn btn-delete-select'><i className='fas fa-times-circle'> </i>
                                    </button>
                                    }
                                    <div className='text-danger'>{this.state.errors.instruction_date}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Instruction By</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='instruction_by'
                                               name='instruction_by'
                                               label='Instruction By'
                                               onChange={(e) => this.handleChange(e, 'instruction_by')}
                                               value={this.state.instruction_by}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.instruction_by}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>PIC Case</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='pic_case'
                                               name='pic_case'
                                               label='PIC Case'
                                               onChange={(e) => this.handleChange(e, 'pic_case')}
                                               value={this.state.pic_case}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.pic_case}</div>
                                </div>
                            </div>

                            <div className='col-12 text-center'>
                                <Button
                                    variant='contained'
                                    className='mr-3'
                                    onClick={() => this.handleGo('/types')}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    className=''
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton && 'disabled' }
                                >
                                    Submit{ this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i> }
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>

                <ToastContainer autoClose={3000} />

            </div>
        )
    }
}

export default ProjectsAdd;
